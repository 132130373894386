.sketch-info {
    padding: 2em;

    a {
        color: white;
        
        &:hover {
            color: white;
        }
    }
}
