.polysketcheslist {
    margin-top: 1em;

    .importall {
        margin: 2em 1em;
        font-weight: bold;
    }

    .polysketch {
        text-align: center;

        img {
            width: 128px;
        }
    }
}