.home-hero {
    padding: 0 0 3em 0;

    .carousel {
        .carousel-inner {
            height: 30em;

            .carousel-item {
                a {
                    color: white;
                }

                .carousel-caption {
                    background: rgba(0, 0, 0, 0.2);
                    border-radius: 1em;
                }

                img {
                    width: 100%;
                    height: 30em;
                    object-fit: cover;
                }
            }
        }
    }

}