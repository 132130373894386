.sketcheditor {
    .update-button {
        text-align: right;

        .btn {
            font-size: 0.9em;
            padding: 0.5em 1em;
        }
    }

    .nav-tabs {
        justify-content: center;
    }

    .tab-pane {
        >h2 {
            padding: 0.1em 0.5em;
            text-decoration: underline;
            text-align: center;
        }

    }

    form {
        width: 100%;
    }
}