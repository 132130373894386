@import '~react-pro-sidebar/dist/scss/styles.scss';

.sidenav {
    z-index: 100;
    height: 100vh;
    position: fixed;

    &.collapsed {

        @media(max-width: 1024px) {
            width: 0px;
            min-width: 0px;
        }

        .pro-sidebar-header {
            .toggler {
                text-align: center;
            }

        }
    }

    .pro-sidebar-inner {
        background: #323232;
    }

    .pro-menu {
        h3 {
            text-align: center;
            border-bottom: 1px solid #afafaf;
            margin: 0 0.5em 0.5em 0.5em;
            padding: 0.3em;
        }

        .comingsoon {
            text-align: center;
            display: block;
            font-weight: bold;
            margin-top: 1em;
        }

        .pro-menu-item.active {
            color: #00cb82;
        }

        .poly-link {
            svg {
                color: #ff00bc
            }
        }
    }

    .searchbar {
        background: #828282;
        padding: 0.2em 0.5em;
        font-family: "Montserrat", sans-serif;
        font-weight: bold;
        color: #ffffff;
        font-size: 1.3em;
        border: 0;
        border-radius: 0.3em;
        width: 100%;
    }

    .pro-sidebar-header {
        text-overflow: ellipsis;
        white-space: nowrap;

        .toggler {
            text-align: right;

            .toggleicon {
                font-size: 2em;
                cursor: pointer;
                margin: 0.2em 0.5em;
            }
        }

    }

    .disabled {
        pointer-events: none;
    }

    .pro-sidebar-content {
        overflow-y: auto;

        /* width */
        &::-webkit-scrollbar {
            width: 7px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #323232;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #fafafa;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #b0b0b0;
        }
    }

    .pro-sidebar-footer {
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 1em;
        font-size: 1.2em;

        .socials {
            padding-bottom: 0.2em;
            font-size: 1.2em;

            a {
                padding: 0.5em;
            }

            border-bottom: 1px solid #afafaf;
        }

        .support {
            padding-top: 0.5em;

            a {
                padding: 0.2em 0;
                font-weight: bold;
                display: block;
            }

            img {
                max-width: 100%;
            }
        }

    }
}