.sketchedit {
    display: flex;

    >div {
        display: flex
    }

    >.viewer {
        flex-grow: 1;

        .sketchviewer {
            width: 100%;
        }
    }

    >.editor {
        width: 24em;
    }

}